import React from "react";

const Hero = () => {
  return (
    <div className="relative flex items-center bg-hero-pattern bg-cover bg-center backimg h-[669px]">
      <div className="absolute inset-0 bg-white/30 backdrop-blur-[2px]" />

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="max-w-2xl">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-primary mb-6 textbig">
            Hilfe fürs Härz,
            <br />
            <span className="relative textbig">
              Unterstützig für d'Seel
              {/* <div className="absolute -bottom-2 left-0 w-48 h-1 bg-primary" /> */}
            </span>
          </h1>

          <p className="text-lg sm:text-xl text-[#8DA399] mb-8 max-w-xl spantexti">
            Mir biete Sterbebegleitig, Hypnose und Reiki-Behandlige, wo dir
            und dine Liebschte Friede, Stärk und Balance schenke.
          </p>

          <button
            className="bg-[#F5F5F5] text-[#2C2C2C] font-bold hover:bg-[#F5F5F5] rounded-full px-8 py-5 text-lg" style={{fontSize:'18px'}}
          >
            Lerne üsi Dienstleistungen kenne
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;