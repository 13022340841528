const About = () => {
    return (
        <div className="about-us-container bg-white"  id="about">
          <div className="about-us-content">
            <div className="about-us-image">
              <img
                src="./ab2.jpg"
                alt="Relaxing therapy session"
                className="therapy-image"
              />
            </div>
            <div className="about-us-text">
              <h2>Über Uns</h2>
              <p>
                Mir sind e Team, wo sich druf konzentriert, Mensche in schwierige
                Situatioune zu unterstütze. Mit Herz und Verstand begleite mir eusi
                Kunde in dr letzte Phase vom Läbe, bi Verhaltensänderige dur
                Hypnose, oder bi dr Wiederherstellig vo dr Energie mit Reiki. Mir
                schaffe e ruhige und vertrauensvolle Umgäbig für dich und dini
                Liebschte.
              </p>
              {/* <button className="session-button">Book a Session</button> */}
              <button 
            className="bg-[#F5F5F5] hover:bg-[#F5F5F5] text-[#2C2C2C] w-full max-w-[230px] py-2 rounded-full"
           
          >
            Book a Session
          </button>
            </div>
          </div>
        </div>
      );
    };
    

export default About;
