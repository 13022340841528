const Footer = () => {
  return (

    <footer className="py-6 px-4 bg-[#FFFFFF]">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center gap-4">
        <div className="flex items-center space-x-2">
          <img src="./logo.png" alt="logo" className="logo" />
          <span className="font-semibold text-[#282C4B] textbig">ESDARF LEICHSTEIN</span>
        </div>

        <div className="text-sm text-[#282C4B]-500 textbig">
          © 2024 Kutiza. All rights reserved
        </div>

        <div className="flex items-center gap-6">
          <div className="flex items-center space-x-4">
            <a  className="text-sm text-[#282C4B]-600 hover:text-[#282C4B] textbig">
              Privacy Policy
            </a>
            <a  className="text-sm text-[#282C4B]-600 hover:text-[#282C4B] textbig">
              Terms
            </a>
            <a href="#contact" className="text-sm text-[#282C4B]-600 hover:text-[#282C4B] textbig">
              Get in Touch
            </a>
          </div>

          <div className="flex items-center space-x-4">
            {/* Facebook SVG */}
            <a href="#" className="text-gray-400 hover:text-gray-600">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.249023" width="40" height="39.5" rx="8" fill="white" />
                <g clip-path="url(#clip0_4_31)">
                  <path d="M20 33.249C27.1797 33.249 33 27.4287 33 20.249C33 13.0693 27.1797 7.24902 20 7.24902C12.8203 7.24902 7 13.0693 7 20.249C7 27.4287 12.8203 33.249 20 33.249Z" fill="#2C2C2C" />
                  <path d="M23.2691 20.7576H20.9494V29.2559H17.4349V20.7576H15.7634V17.771H17.4349V15.8383C17.4349 14.4562 18.0914 12.292 20.9807 12.292L23.584 12.3029V15.2019H21.6951C21.3853 15.2019 20.9496 15.3567 20.9496 16.016V17.7737H23.5762L23.2691 20.7576Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_4_31">
                    <rect width="26" height="26" fill="white" transform="translate(7 7.24902)" />
                  </clipPath>
                </defs>
              </svg>


            </a>
            {/* LinkedIn SVG */}
            <a href="#" className="text-gray-400 hover:text-gray-600">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.249023" width="40" height="39.5" rx="8" fill="white" />
                <g clip-path="url(#clip0_4_26)">
                  <path d="M30.9945 31.2488V31.2479H31V23.1794C31 19.2322 30.1503 16.1917 25.5358 16.1917C23.3174 16.1917 21.8288 17.409 21.221 18.5631H21.1569V16.5602H16.7816V31.2479H21.3374V23.9751C21.3374 22.0602 21.7004 20.2085 24.0719 20.2085C26.4084 20.2085 26.4433 22.3938 26.4433 24.0979V31.2488H30.9945Z" fill="#2C2C2C" />
                  <path d="M9.3631 16.5613H13.9244V31.249H9.3631V16.5613Z" fill="#2C2C2C" />
                  <path d="M11.6418 9.24902C10.1834 9.24902 9 10.4324 9 11.8909C9 13.3493 10.1834 14.5574 11.6418 14.5574C13.1003 14.5574 14.2837 13.3493 14.2837 11.8909C14.2828 10.4324 13.0993 9.24902 11.6418 9.24902V9.24902Z" fill="#2C2C2C" />
                </g>
                <defs>
                  <clipPath id="clip0_4_26">
                    <rect width="22" height="22" fill="white" transform="translate(9 9.24902)" />
                  </clipPath>
                </defs>
              </svg>

            </a>
            {/* Instagram SVG */}
            <a href="#" className="text-gray-400 hover:text-gray-600">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.249023" width="40" height="39.5" rx="8" fill="white" />
                <g clip-path="url(#clip0_4_36)">
                  <path d="M8.62542 9.01793C6.58225 11.1402 7.00042 13.3946 7.00042 20.2434C7.00042 25.9309 6.00809 31.6325 11.2016 32.9748C12.8233 33.3918 27.1927 33.3919 28.8123 32.9726C30.9746 32.4147 32.7339 30.6608 32.9744 27.6025C33.008 27.1757 33.008 13.3188 32.9733 12.8833C32.7177 9.62568 30.7124 7.74827 28.0702 7.36802C27.4646 7.28027 27.3433 7.25427 24.2363 7.24885C13.2155 7.25427 10.7997 6.76352 8.62542 9.01793Z" fill="#2C2C2C" />
                  <path d="M19.9976 10.6495C16.064 10.6495 12.3287 10.2996 10.9019 13.9613C10.3126 15.4736 10.3982 17.4377 10.3982 20.25C10.3982 22.7179 10.3191 25.0373 10.9019 26.5377C12.3254 30.2015 16.0911 29.8505 19.9954 29.8505C23.7622 29.8505 27.6459 30.2427 29.09 26.5377C29.6804 25.0102 29.5938 23.0754 29.5938 20.25C29.5938 16.4995 29.8007 14.0783 27.9818 12.2604C26.1401 10.4188 23.6495 10.6495 19.9933 10.6495H19.9976ZM19.1374 12.3796C27.3426 12.3666 28.3869 11.4544 27.8106 24.1262C27.6059 28.6079 24.1934 28.1161 19.9987 28.1161C12.3504 28.1161 12.1304 27.8973 12.1304 20.2457C12.1304 12.5053 12.7371 12.3839 19.1374 12.3774V12.3796ZM25.1218 13.9732C24.4859 13.9732 23.9702 14.4889 23.9702 15.1248C23.9702 15.7607 24.4859 16.2764 25.1218 16.2764C25.7577 16.2764 26.2734 15.7607 26.2734 15.1248C26.2734 14.4889 25.7577 13.9732 25.1218 13.9732ZM19.9976 15.3198C17.2752 15.3198 15.0684 17.5276 15.0684 20.25C15.0684 22.9724 17.2752 25.1792 19.9976 25.1792C22.72 25.1792 24.9257 22.9724 24.9257 20.25C24.9257 17.5276 22.72 15.3198 19.9976 15.3198ZM19.9976 17.0499C24.228 17.0499 24.2334 23.4502 19.9976 23.4502C15.7683 23.4502 15.7618 17.0499 19.9976 17.0499Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_4_36">
                    <rect width="26" height="26" fill="white" transform="translate(7 7.24902)" />
                  </clipPath>
                </defs>
              </svg>

            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;