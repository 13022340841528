import { useState, useEffect } from "react";

interface Testimonial {
  id: number;
  name: string;
  text: string;
}

const testimonials: Testimonial[] = [
  {
    id: 1,
    name: "Anna S.",
    text: "Die Sterbebegleitung het us enorm gholfe, mit däm schweri Zyt umzegah. D Unterstützig wo mir übercho hend, isch unbezahlbar gsi.",
  },
  {
    id: 2,
    name: "Markus T.",
    text: "Hypnose het mis Läbe komplett verändert. I ha so viel meh Selbstvertroue, und i fühle mich ganz anders.",
  },
  {
    id: 3,
    name: "Clara M.",
    text: "Nach jedere Reiki-Sitzig fühle i mich wie neu. Es isch so entspannend und erfrischend!",
  },
  {
    id: 4,
    name: "Lukas P.",
    text: "D Meditationstechnike händ mir gholfe, mit Stress umzgah und min Tag besser z meistere.",
  },
  {
    id: 5,
    name: "Nina B.",
    text: "Ich bi so dankbar für d Unterstützung bim Coaching! Es het mich richtig vorwärts brocht.",
  },
  {
    id: 6,
    name: "Jan H.",
    text: "Die Sessions händ mir e völlig nöie Perspektive uf mis Läbe gäh. Unglaublich wertvoll!",
  },
];

const TestimonialCarousel = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const visibleTestimonials = 3;
  const totalPages = Math.ceil(testimonials.length / visibleTestimonials);

  useEffect(() => {
    if (!isPaused) {
      const timer = setInterval(() => {
        setCurrentPage((prev) => (prev + 1) % totalPages);
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [isPaused, totalPages]);

  const getVisibleTestimonials = () => {
    const startIndex = currentPage * visibleTestimonials;
    return testimonials.slice(startIndex, startIndex + visibleTestimonials);
  };

  return (
    <div className="py-16 bg-white" id="testimonials">
          <div className="flex items-center justify-center mb-12">
          <h2 className="text-3xl font-bold textbig">Das säge üsi Kunde</h2>
        </div>
      <div
        className="max-w-[1200px] mx-auto bg-[#F5F5F5] rounded-3xl p-12"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
    

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {getVisibleTestimonials().map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-white rounded-2xl p-8 shadow-sm border border-gray-100"
            >
              <div className="flex flex-col items-center text-center space-y-4">
                <div className="w-16 h-16 bg-gray-200 rounded-full mb-4" />
                <p className="text-gray-700 text-sm leading-relaxed mb-4">
                  "{testimonial.text}"
                </p>
                <h3 className="font-semibold">{testimonial.name}</h3>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center gap-2 mt-8">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index)}
              className={`w-3 h-3 rounded-full transition-colors ${
                index === currentPage ? "bg-gray-400" : "bg-gray-200"
              }`}
              aria-label={`Go to page ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
