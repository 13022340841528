import React from "react";

const Services = () => {
  return (
    <div className="bg-white" id="services">
    <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
      <h1 className="text-4xl font-bold text-center text-gray-900 mb-16 textbig">Üsi Dienstleistungen</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {/* Service Card 1 */}
        <div className="flex flex-col items-center text-center">
          <div className="w-64 h-64 rounded-full overflow-hidden mb-6 transform transition-transform hover:scale-105">
            <img
              src="./service1.jpg"
              alt="Sterbebegleitung"
              className="w-full h-full object-cover"
            />
          </div>
          <h2 className="text-2xl  text-gray-900 mb-4  textbig">Sterbebegleitung</h2>
          <p className="text-gray-600 mb-6 px-4 text-sm spantexti">
          Mir sind für Mensche und Familie do, wo sich im letzte Abschnitt vom Läbe befinde. Mir unterstütze mit Fürsorg, Offenheit und Hilf bim Umgang mit schwierige Thema.          </p>
          <button 
            className="bg-[#F5F5F5] hover:bg-[#F5F5F5] text-[#2C2C2C] w-full max-w-[230px] py-2 rounded-full"
          >
            Learn More
          </button>
        </div>

        {/* Service Card 2 */}
        <div className="flex flex-col items-center text-center">
          <div className="w-64 h-64 rounded-full overflow-hidden mb-6 transform transition-transform hover:scale-105">
            <img
              src="./service2.jpg"
              alt="Hypnotherapy"
              className="w-full h-full object-cover"
            />
          </div>
          <h2 className="text-2xl text-gray-900 mb-4 textbig">Hypnotherapy</h2>
          <p className="text-gray-600 mb-6 px-4 text-sm spantexti">
            Hypnose hilft dir, positive Verhaltensmuster und Gedanke zu stärke. Egal, ob du alti Gewohnheite loswähle oder meh Selbstvertroue gwünne wösch – mir helfe dir, dini Ziele z'erreiche.
          </p>
          <button 
            className="bg-[#F5F5F5] hover:bg-[#F5F5F5] text-[#2C2C2C] w-full max-w-[230px] py-2 rounded-full"
           
          >
            Learn More
          </button>
        </div>

        {/* Service Card 3 */}
        <div className="flex flex-col items-center text-center">
          <div className="w-64 h-64 rounded-full overflow-hidden mb-6 transform transition-transform hover:scale-105">
            <img
              src="./service3.jpg"
              alt="Reiki-Behandlung"
              className="w-full h-full object-cover"
            />
          </div>
          <h2 className="text-2xl  text-gray-900 mb-4 textbig">Reiki-Behandlung</h2>
          <p className="text-gray-600 mb-6 px-4 text-sm spantexti">
            Reiki fördert Entspannig, Energie und Balance. Mir löse Blockade und helfe dir, dini inneri Stärke und Friede wiederzz'finge.
          </p>
          <button 
            className="bg-[#F5F5F5] hover:bg-[#F5F5F5] text-[#2C2C2C] w-full max-w-[230px] py-2 rounded-full"
          
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Services;