import { useState } from "react";


const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: '',
        message: ''
      });
    
      const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        // toast({
        //   title: "Message sent!",
        //   description: "We'll get back to you soon.",
        // });
        setFormData({
          name: '',
          email: '',
          phone: '',
          location: '',
          message: ''
        });
      };
    
      const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      };
    
      return (
        <div className="min-h-screen bg-[#FFFFFF] py-12 px-4 sm:px-6 lg:px-8" id="contact">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-12">
              <h1 className="text-3xl font-bold text-gray-900 mb-2 textbig">Kontaktier üs</h1>
              <p className="spantexti">
                Hesch Froge oder möchtesch meh über üsi Dienstleistungen erfahre? Mir sind gärn für dich do.
              </p>
            </div>
    
            <div className="bg-white rounded-lg  overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
              <div className="p-6 lg:p-8">
                <h2 className="text-2xl font-semibold text-[#282C4B] mb-6 text-center textbig">Wo mir sind?</h2>
                <p className="spantexti mb-6">
                  Bsüech üs an üsem Standort, oder nimm Kontakt mit üs uf, für meh
                  <br />
                  <span className="font-medium spantexti">Infos: <span className="font-medium color-[#000000]">Heilzentrum Zürich, Bahnhofstrasse 25, 8001 Zürich, Schweiz.</span></span>
                </p>
    
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Name *"
                      required
                      className="w-full px-4 py-3 border border-gray-300  focus:ring-contact-blue focus:border-contact-blue" style={{borderColor:'#E0E0E0'}}
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="info@deingeschäft.ch"
                      required
                      className="w-full px-4 py-3 border border-gray-300  focus:ring-contact-blue focus:border-contact-blue" style={{borderColor:'#E0E0E0'}}
                    />
                  </div>
                  <div>
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="+41-123-456-789"
                      className="w-full px-4 py-3 border border-gray-300  focus:ring-contact-blue focus:border-contact-blue" style={{borderColor:'#E0E0E0'}}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      placeholder="Standort, falls verfügbar"
                      className="w-full px-4 py-3 border border-gray-300  focus:ring-contact-blue focus:border-contact-blue" style={{borderColor:'#E0E0E0'}}
                    />
                  </div>
                  <div>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Message"
                      rows={4}
                      className="w-full px-4 py-3 border border-gray-300  focus:ring-contact-blue focus:border-contact-blue" style={{borderColor:'#E0E0E0'}}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-[#F5F5F5] text-[#2C2C2C] px-6 py-3 rounded-md hover:bg-[#F5F5F5] transition-colors duration-200" 
                  >
                    NACHRICHT SCHICKE
                  </button>
                </form>
              </div>
    
              <div className="relative h-96 lg:h-auto">
                <iframe
                  title="Location Map"
                  className="absolute inset-0 w-full h-full"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2702.3685467177256!2d8.537679776654362!3d47.37673660429639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a08cc0e6e41%3A0x95f45e1c86778c3e!2sBahnhofstrasse%2C%208001%20Z%C3%BCrich!5e0!3m2!1sen!2sch!4v1709728391345!5m2!1sen!2sch"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      );
    };

export default Contact;